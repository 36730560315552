<template>
  <UiFooterPage
    :text-copyright="`© ${dataYear} Koelner Rawlplug IP`"
    title="Warto być na bieżąco"
    description="Dołącz do naszego Newslettera aby nie ominęły Cię:"
  >
    <template #newsletterFooter>
      <div class="flex flex-wrap items-center md:flex-nowrap">
        <T3Renderer
          v-if="props.infobox && Object.keys(props.infobox).length"
          :content="[props.infobox]"
          :frame="false"
        />
        <component
          :is="isEcommerceEnabled ? 'T3CeRawlplugNewsletter' : null"
          v-if="props.newsletter?.content"
          class="ml-auto mt-12 w-full md:w-1/2"
          :content="props.newsletter.content"
        />
      </div>
    </template>
    <template #bottomLeft>
      <UiFooterSocial
        v-if="props?.social?.length"
        :socials="props.social"
      />
    </template>
    <template #bottomRight>
      <UiFooterLinks
        v-if="footerNavWithOptionalCookies?.length"
        class="order-1 md:order-2 md:w-1/2"
        :footer-links="footerNavWithOptionalCookies"
      />
    </template>
  </UiFooterPage>

  <ClientOnly>
    <UiCookiesManager
      v-if="!isPiwikEnabled"
      ref="refUiCookies"
      :labels="labelsCookies"
      :cookies-banner-visible="cookiesBannerVisible"
      :cookies-state="cookiesState"
      :cookies-list="cookiesList"
      @check-accepted-consent="handleCheckAcceptedConsent($event)"
      @accept-necessary="handleAcceptNecessary"
    >
      <template #cookiesText>
        <i18n-t
          keypath="our_website_uses_cookies_including_third_party_cookies"
          tag="p"
          class="m-0 text-sm [&>a]:text-primary [&>a]:hover:underline"
        >
          <template #policyLink>
            <NuxtLink :to="privacyPolicyLink">
              {{ t('privacy_policy') }}
            </NuxtLink>
          </template>
        </i18n-t>
      </template>
    </UiCookiesManager>
  </ClientOnly>
</template>

<script lang="ts" setup>
import type { T3InitialData } from '@t3headless/nuxt-typo3'
import type { UiCookiesManagerState } from '@ui/components/UiCookiesManager/UiCookiesManager.types'
import type { UiFooterSocialInterface } from '@ui/components/UiFooterSocial/UiFooterSocial.vue'
import useCookiesManager from '../../composables/useCookiesManager/useCookiesManager'
import useConditionalRender from '../../composables/useConditionalRender/useConditionalRender'
import { getConsentVariable } from '../../utils/replaceConsentVariable'

interface AppFooter {
  infobox: Pick<T3InitialData, 'infobox'>['infobox']
  newsletter: Pick<T3InitialData, 'newsletter'>['newsletter']
  social: UiFooterSocialInterface[] | undefined
  footerNavigation: Pick<T3InitialData, 'footerNavigation'>['footerNavigation']
}

const props = defineProps<AppFooter>()

const dataYear = computed(() => new Date().getFullYear())

const { isEcommerceEnabled } = useConditionalRender()
const { $config } = useNuxtApp()
const isPiwikEnabled = $config.public?.piwik?.host && $config.public?.piwik?.id

const { t, locale } = useI18n()
const refUiCookies = ref(null)
const footerNavWithOptionalCookies = computed(() => {
  if (Array.isArray(props.footerNavigation)) {
    const footerNavCookies = [...props.footerNavigation]
    footerNavCookies.push({
      title: t('manage_cookies'),
      action: () => refUiCookies.value?.openModal(),
    })
    return footerNavCookies
  }
  return props.footerNavigation
})

const privacyPolicyLink = computed(() => getConsentVariable(locale.value, 'privacyPolicyUrl'))

const { setCookiesManagerState, cookiesState } = useCookiesManager()

const cookiesBannerVisible = ref(false)
if (import.meta.client) {
  cookiesBannerVisible.value = !cookiesState.value?.necessary
}

const cookiesList = computed(() => {
  return [
    {
      name: 'necessary',
      checked: true,
      disabled: true,
      header: t('necessary_cookies_always_active'),
      text: t('by_default_we_use_essential_cookies_to_ensure_the_correct_display_and_functioning_of_the_website_ensure_website_security_and_prevent_website_errors'),
    },
    {
      name: 'analytical',
      checked: false,
      disbled: false,
      header: t('functional_cookies'),
      text: t('in_addition_to_the_necessary_cookies_by_default_we_use_cookies_supporting_analytical_tools_to_measure_the_number_of_visits_to_the_website_and_user_activity_google_analytics'),
    },
    {
      name: 'advertising',
      checked: false,
      disabled: false,
      header: t('advertising_cookies'),
      text: t('other_types_of_cookies_eg_marketing_or_social_media_cookies_or_other_technologies_that_increase_the_efficiency_and_personalization_of_our_website_and_targeted_advertising'),
    },
  ]
})

const labelsCookies = computed(() => {
  return {
    cookiesHeader: t('select_your_cookies'),
    btnUnderstand: t('understand'),
    btnGoToSetting: t('open_settings'),
    btnAccept: t('i_accept_functional_and_advertising_cookies'),
    btnSave: t('save_cookie_settings'),
    modalHeader: t('your_settings'),
  }
})

function handleCheckAcceptedConsent(event: UiCookiesManagerState) {
  setCookiesManagerState(event)
}

function handleAcceptNecessary() {
  const cookie = {
    necessary: true,
    analytical: false,
    advertising: false,
  }
  cookiesBannerVisible.value = false
  setCookiesManagerState(cookie)
}
</script>
